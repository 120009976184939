import React, { useState, useEffect } from 'react';
import StaffImageCard1 from "../components/StaffImageCard1";
import client from '../SanityClient';

function ThirdPage() {
  const [employeeData, setEmployeeData] = useState([]);

  // Function to fetch employee data
  const getEmployeeData = async () => {
    const data = await client.fetch('*[_type == "employees"]'); // Fetch employee data from Sanity
    setEmployeeData(data);  // Update state with the fetched data
  };

  // Fetch employee data on component mount
  useEffect(() => {
    getEmployeeData();  // This runs once when the component mounts
  }, []);  // Empty dependency array means this effect runs only once

  // Filter out invalid or missing images
  const validEmployeeData = employeeData.filter((employee) => {
    return employee.employeeImg && typeof employee.employeeImg === 'object' && employee.employeeImg._type === 'image';
  });

  // Sort employee data by the most recent update
  const sortedArray = validEmployeeData.sort((a, b) => {
    const dateA = new Date(a._updatedAt);
    const dateB = new Date(b._updatedAt);
    return dateB - dateA; // Descending order
  });

  return (
    <div className="self-stretch bg-white overflow-hidden flex flex-col items-start justify-start py-[50px] px-[50px] md:items-center md:justify-start md:pl-5 md:pr-5 md:box-border sm:pl-[15px] sm:pr-[15px] sm:box-border md:py-5">
      <div className="self-stretch flex flex-col items-start justify-center px-[50px] lg:px-0">
        <div className="relative text-left text-[32px] mb-10">¡CONÓCENOS!</div>
        <div className="self-stretch flex flex-row flex-wrap items-start justify-between gap-[50px] lg:gap-[10px] md:gap-[20px] md:justify-evenly">
          {validEmployeeData && sortedArray.map((employee, index) => {
            return (
              <StaffImageCard1
                key={index}
                employeeImageId={employee.employeeImg}
                employeeName={employee.name}
                designation={employee.designation}
              />
            );
          })}
        </div>
      </div>
    </div>
  );
}

export default ThirdPage;
